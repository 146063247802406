.header{
  background-color: #1F2332;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  &__wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &__nav ul{
      display: flex;
      align-items: center;
      gap: 30px;
    }
    &__nav ul li a{
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF66;
    }
  &__phone a{
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF66;
  }

  @media screen and (max-width: 1000px) {
    &__wrap{
      gap: 10px;
    }

    &__nav{
      display: none;
    }
    &__btn a{
      text-align: center;
    }
    &__phone a{
      text-wrap: nowrap;
      min-width: 170px;
      display: block;
    }
  }
  @media screen and (max-width: 390px) {
    &__btn{
      display: none;
    }
  }
}

