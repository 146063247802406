.container__fluid{
  max-width: 1800px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.container{
  max-width: 1400px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;
}