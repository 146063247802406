@import "lib/_normalizer";
@import "_container.scss";
@import "_header.scss";
@import "_font.scss";
@import "_footer.scss";
@import "_button.scss";
@import "_intro.scss";
@import "_form.scss";
@import "_services.scss";
@import "_advantages";



.animate{
  opacity: 0;
}

.element-show{
  opacity: 1;
  transition: opacity 1s;
}

.intro__form--error{
  color: red;
  display: none;
}
.intro__form--success{
  color: green;
  display: none;
}