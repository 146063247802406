.advantages{
  background-color: #FFFFFF66;
  padding-top: 120px;
  padding-bottom: 120px;
}

.advantages ul {
  line-height: 1.5;
  margin-top: 80px;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;

}

.advantages ul li {
  list-style-type: none;
  counter-increment: item;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.advantages ul li:before {
  content: counter(item);
  display: inline-block;
  width: 45px;
  height: 55px;
  font-family: "Indie Flower", cursive;
  margin-right: 5px;
  background-color: #F6110F;
  color: #fff;
  font-weight: bold;
  font-size: 46px;
  padding: 0 8px 8px;
  border-radius: 3px;
  line-height: 1.3;
  display: flex;align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
@media screen and (max-width: 1000px) {
  .advantages {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
