.btn{

  &__primary{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #E43331;
    border-radius: 10px;
  }

  &__primary--lg{
    font-size: 24px;
  }
  @media screen and (max-width: 1000px) {
    &__primary{
      font-size: 18px;
    }
  }
}