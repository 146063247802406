.intro{
  padding-top: 120px;
  padding-bottom: 180px;
  background: linear-gradient(281.42deg, #32364E -11.09%, #171B27 109.87%);
  &__img img{
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 600px;
    width: 100%;
    border-radius: 30px;
  }
  &__wrap{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  &__title{
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 66px;
    text-align: center;
    color: #fff;
  }
  &__subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF66;
  }
  &__desc{
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF66;
    line-height: 1.5;
  }
  &__form{
    margin-top: 40px;
  }
  @media screen and (max-width: 1000px) {
    padding-top: 60px;
    padding-bottom: 80px;
    &__img img{
      margin-top: 10px;
    }
    &__subtitle{
      font-size: 18px;
    }
    &__title{
      font-size: 28px;
      line-height: 35px;
    }
    &__desc{
      font-size: 21px;
    }
  }

}




