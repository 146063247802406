.services{
  background-color: #FFFFFF66;
  padding-top: 120px;
  padding-bottom: 120px;
  &__title{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 66px;
    text-align: center;
    color: #444444;
  }
  &__list{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
  }
  &__item{
    width: calc(33.3% - 70px);
    padding: 20px;
    border: 1px solid #888888;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 20px;
  }



  &__desc{
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #444444;
    min-height: 140px;
  }
  @media screen and (max-width: 1000px) {
    padding-top: 60px;
    padding-bottom: 60px;
    &__item{
      width:100%;
    }
    &__desc{
      min-height: 50px;
    }

  }
}

.services--why{
  background-color: #FFFFFF66;

}

