.form{
  padding: 50px 80px;
  border: 3px solid #F6110F;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-direction: row;
  flex-wrap: wrap;
}
.form--sm{
  max-width: 800px;
}

.form input{
  border-radius: 20px;
  padding: 24px;
  color: #444444;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  border: none;
  outline: 0;
  width: 100%;
}
.form__contact{
  margin-top: 20px;

}

.form__contact a{
  font-size: 36px;
  color: #FFFFFF66;
}

.form__col{
  width: 100%;
  max-width: 300px;
}

.form__submit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.form__desc{
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF66;
  font-weight: 500;
  font-size: 21px;
  text-align: center;
  max-width: 630px;
  line-height: 1.5;

}

@media screen and (max-width: 1000px) {
  .form{
    padding: 20px 10px;
  }
  .form__contact a{
    font-size: 18px;
  }
  .form__contact{
    margin-top: 10px;
  }
}